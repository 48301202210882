import {Helmet} from "react-helmet"
import shareImg from "../assets/images/meta/share.png"
import Background from "../components/Background/Background"
import mainHeroImg from "../assets/images/graphics/mainHero.jpg"
import Nav from "../components/Nav/Nav"
import * as React from "react"

const AboutPage = ({location}) => {
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About | Nocturne X</title>
                <meta name="description"
                      content="Enter an otherwordly forest of sound and light on planet Nocturne X in this transformative multi-sensory art experience, where the alien environment reacts to your every touch and movement."/>
                <meta property="og:image" content={shareImg}/>
                <link rel="canonical" href="https://nocturne-x.com"/>
            </Helmet>

            <Background/>

            <Nav />

            <div className="Hero">
                <img
                    src={mainHeroImg}
                    alt="Nocturne X"
                    className="Hero__graphic"
                />

            </div>

        </main>
    )
}

export default AboutPage
